
import { Table } from '@/services/recipes.service';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SealsLabel from '@/components/labels/SealsLabel.vue';
import RoundLabelValues from '@/components/recipe-detail/labels/RoundLabelValues.vue';
import DownloadPreventButton from '@/components/recipe-detail/DownloadPreventButton.vue';
import RecipeService, { FullData, Recipe } from '@/services/recipes.service';

@Component({
  components: { SealsLabel, RoundLabelValues, DownloadPreventButton },
})
export default class LinearLabel extends Vue {
  @Prop() private recipe!:Recipe;
  @Prop() private table!:Table;

  data():any {
    return {
    };
  }


  get showValidateWarning() {
      return false; //this.identity && this.identity.account.status == 9;
  }

  get isAuthenticated() {
    return this.$store.getters.isAuhtenticated;
  }

  get identity() {
    return this.$store.getters.getIdentity;
  }

  get downloadRateLimitExceeded():boolean {
    return this.table.download_count >= 3;
  }

  reloadRecipe() {
    this.loadModel(this.recipe.id);
  } 

  loadModel(id:number):void {
    RecipeService.getOneFull(id).then((model:FullData) => {
      this.$store.commit('updateRecipe', model.label);
      this.$store.commit('updateTable', model.table);
    }).catch(error => {
      this.$router.push('/home');
    });
  }

  // showValidateWarning:boolean {
  //   if(this.isAuthenticated) {
  //     return this.identity.account.recipe_limit > 1;
  //   }

  //   return false;
  // }

  geColumnCount():string {
    return this.recipe != null && !this.recipe.include_serving ? '2' : '3';
  }

  getLabelColumnWidth():string {
    return this.recipe != null && !this.recipe.include_serving ? '60%' : '50%';
  }

  getDataColumnWidth():string {
    return this.recipe != null && !this.recipe.include_serving ? '40%' : '25%';
  }

  getDataSubcolumnWidth():string {
    return this.recipe != null && !this.recipe.include_serving ? '30%' : '28%';
  }

  hasOptionalNutrientsEnabled() {
    if(this.recipe != null && this.recipe != undefined
        && (
             this.recipe.extend_other_nutrients_folid_acid
          || this.recipe.extend_other_nutrients_calcium
          || this.recipe.extend_other_nutrients_copper
          || this.recipe.extend_other_nutrients_phosphorus
          || this.recipe.extend_other_nutrients_iron
          || this.recipe.extend_other_nutrients_magnesium
          || this.recipe.extend_other_nutrients_manganese
          || this.recipe.extend_other_nutrients_niacin
          || this.recipe.extend_other_nutrients_potassium
          || this.recipe.extend_other_nutrients_selenium
          || this.recipe.extend_other_nutrients_vitamin_a
          || this.recipe.extend_other_nutrients_thiamine
          || this.recipe.extend_other_nutrients_riboflavin
          || this.recipe.extend_other_nutrients_vitamin_b6
          || this.recipe.extend_other_nutrients_vitamin_b12
          || this.recipe.extend_other_nutrients_vitamin_d
          || this.recipe.extend_other_nutrients_vitamin_e
          || this.recipe.extend_other_nutrients_vitamin_c
          || this.recipe.extend_other_nutrients_vitamin_k
          || this.recipe.extend_other_nutrients_zinc
          || this.recipe.extend_other_nutrients_pantothenic_acid
          || this.recipe.extend_other_nutrients_chromium
          || this.recipe.extend_other_nutrients_fluor
          || this.recipe.extend_other_nutrients_iodine
        )) {
          return true;
    }

    return false;
  }
}
